import React, { useMemo, useState } from "react";
import {
  CreateEmployee,
  EmployeeAddEditForm,
  EmployeeDetails,
  EmployerMetadata,
  UpdateEmployee,
} from "../../../models";
import { useDispatch } from "react-redux";
import {
  clearFormPayload,
  convertDateToNumber,
  dateToInputElemDate,
  getErrorMsgFromResponse,
  getParamFromUrl,
  isEmail,
  isMobile,
  isName,
  isNullOrUndefined,
  isoDateToInputElemDate,
  isValidJoiningDate,
  stringToNumberOrNull,
} from "../../../utils";
import dispatch from "../../../middleware";
import {
  addEmployee,
  checkCreateEmployeeFields,
  getEmployees,
  updateEmployee,
} from "../../../actions/employee";
import { TextInput } from "../../../components/library/text-input";
import { NumberInput } from "../../../components/library/number-input";
import { BaseInput } from "../../../components/library/base-input";
import { useForm } from "../../../hooks/use-form";
import { NativeSelect } from "../../../components/library/native-select";
import { RadioGroup } from "../../../components/library/radio/radio-group";
import { Radio } from "../../../components/library/radio";
import { Content } from "../../../components/library/content";
import { useId } from "../../../hooks/use-id";
import { Button } from "../../../components/library/button";
import {
  CheckCircleIcon,
  PlusIcon,
  WarningIcon,
} from "../../../components/library/icon";

function formatJoinedAt(
  formValue?: string,
  isEditForm: boolean = false
): number | undefined {
  if (!formValue) return undefined;
  // INFO: To support the update of joinedAt converting it to epoch
  if (isEditForm) return new Date(formValue).getTime();
  // INFO: Create employee requires Number(YYYYMMDD)
  return convertDateToNumber(isoDateToInputElemDate(formValue));
}

function renderEmployerSpecificDrowdown<T>(
  metadata: Array<string> | undefined,
  context: {
    label: string;
    placeholder?: string;
    inputProps: T;
  }
) {
  if (!Array.isArray(metadata) || metadata.length < 1) return null;
  return (
    <NativeSelect
      label={context.label}
      placeholder={context.placeholder}
      options={metadata.map((value) => ({ value }))}
      {...context.inputProps}
    />
  );
}

function getDefaultFormState(
  employeeData?: EmployeeDetails | null
): EmployeeAddEditForm {
  return {
    id: employeeData?._id,
    fullName: employeeData?.fullName ?? "",
    joinedAt: employeeData?.joinedAt ?? "",
    email: employeeData?.email ?? "",
    employerEmployeeId: employeeData?.employerEmployeeId ?? "",
    currentMonthlySalary: employeeData?.currentMonthlySalary ?? "",
    designation: employeeData?.designation ?? "",
    mobile: employeeData?.mobile ?? "",
    maxWithdrawalSalaryPercent: !isNullOrUndefined(
      employeeData?.maxWithdrawalSalaryPercent
    )
      ? String(employeeData?.maxWithdrawalSalaryPercent)
      : "",
    maxWithdrawalPerTransaction: !isNullOrUndefined(
      employeeData?.maxWithdrawalPerTransaction
    )
      ? String(employeeData?.maxWithdrawalPerTransaction)
      : "",
    maxWithdrawalPerDay: !isNullOrUndefined(employeeData?.maxWithdrawalPerDay)
      ? String(employeeData?.maxWithdrawalPerDay)
      : "",
    salaryAccountNumber: employeeData?.salaryAccountNumber ?? "",
    isTransactionAllowed: employeeData?.isTransactionAllowed ?? true,
    departmentId: employeeData?.departmentId ?? "",
    gradeId: employeeData?.gradeId ?? "",
    branchId: employeeData?.branchId ?? "",
  };
}

// INFO: Due to backend Shit Code
const WARNING_RESP_TO_CREATE_EMPLOYEE_DTO_KEY_MAP: Partial<
  Record<string, keyof EmployeeAddEditForm>
> = {
  mobile: "mobile",
  email: "email",
  salary: "currentMonthlySalary",
  branch: "branchId",
  department: "departmentId",
  grade: "gradeId",
};

const TRANSACTION_ACCESS_UPDATE_REASON = [
  "PAY_HOLD",
  "AUTHORIZATION_BLOCKED",
  "ADMIN_BLOCKED",
  "REPAYMENT_PENDING",
];

interface AddEmployeeFormProps {
  onEmployeeAdd: () => void;
  editValues?: EmployeeDetails | null;
  isSignedUp?: boolean;
  employerMetadata: EmployerMetadata | null;
}

export default function AddEmployeeForm(props: AddEmployeeFormProps) {
  const storeDispatch = useDispatch();
  const vendorId = getParamFromUrl("vendorId");
  const isEditForm = !!props.editValues;

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [fieldValidationStatus, setFieldValidationStatus] = useState<
    Partial<Record<"email" | "mobile", boolean>>
  >({});
  const clearFieldValidationStatusOfField = (key: "email" | "mobile") => {
    setFieldValidationStatus((current) => {
      const clone = { ...current };
      delete clone[key];
      return clone;
    });
  };

  async function checkFieldValueFromServer(
    key: "email" | "mobile",
    value: string
  ): Promise<boolean> {
    const resp: Partial<Record<"email" | "mobile", boolean>> = await dispatch(
      storeDispatch,
      checkCreateEmployeeFields({ [key]: value })
    );
    setFieldValidationStatus((current) => ({ ...current, [key]: resp[key] }));
    return !!resp[key];
  }

  const isDataValidAfterServerCheck = useMemo(
    () =>
      Object.keys(fieldValidationStatus).every(
        (key) =>
          !!fieldValidationStatus[key as keyof typeof fieldValidationStatus]
      ),
    [fieldValidationStatus]
  );

  const form = useForm<EmployeeAddEditForm>({
    initialValues: getDefaultFormState(props.editValues),
    // TODO: Refactor with composable validation rules
    validationRules: {
      fullName: (value) => {
        if (!value || !isName(value)) return "Please enter a valid Name";
        return null;
      },
      email: (value) => {
        if (!value) return null;
        if (!isEmail(value)) return "Please enter a valid Email Id";
      },
      mobile: (value) => {
        if (!value) return null;
        if (!isMobile(value)) return "Please enter a valid Mobile";
      },
      employerEmployeeId: (value) => {
        if (!value) return "Please enter Employee Id";
      },
      currentMonthlySalary: (value) => {
        if (!value) return "Please enter Monthly Salary";
        if (typeof value !== "number")
          return "Monthly Salary should be number(s) only";
        if (value < 1) return "Monthly Salary should be a positive number";
      },
      joinedAt: (value) => {
        if (!value) return null;
        if (!isValidJoiningDate(value))
          return "Please enter a valid Joining Date";
      },
      salaryAccountNumber: (value) => {
        if (!value) return null;
        const numberValue = Number(value);
        if (Number.isNaN(numberValue))
          return "Please enter a valid Salary Account Number";
        if (value.length < 9 || value.length > 18)
          return "Salary Account Number length should be between 9 & 18 characters";
      },
      maxWithdrawalSalaryPercent: (value) => {
        if (value === undefined || value === null || value === "") return null;
        const numberValue = Number(value);
        if (Number.isNaN(numberValue))
          return "Please enter a valid percentage value";
        if (numberValue < 0 || numberValue > 100)
          return "Please enter a number between 0 to 100";
      },
      maxWithdrawalPerDay: (value) => {
        if (value === undefined || value === null || value === "") return null;
        const numberValue = Number(value);
        if (Number.isNaN(numberValue)) return "Please enter a valid number";
        if (numberValue < 0) return "Please enter a positive number";
      },
      maxWithdrawalPerTransaction: (value) => {
        if (value === undefined || value === null || value === "") return null;
        const numberValue = Number(value);
        if (Number.isNaN(numberValue)) return "Please enter a valid number";
        if (numberValue < 0) return "Please enter a positive number";
      },
    },
    asyncValidationRules: {
      mobile: async (value) => {
        if (!value) {
          clearFieldValidationStatusOfField("mobile");
          return null;
        }
        const isValid = await checkFieldValueFromServer("mobile", value);
        if (!isValid) return "Invalid Mobile";
        return null;
      },
      email: async (value) => {
        if (!value) {
          clearFieldValidationStatusOfField("email");
          return null;
        }
        const isValid = await checkFieldValueFromServer("email", value);
        if (!isValid) return "Invalid Email Id";
        return null;
      },
    },
  });

  const formId = useId();

  async function handleFormSubmit(formData: EmployeeAddEditForm) {
    // TODO: Handle this in clear way
    if (!formData.email && !formData.mobile) {
      form.setFieldError("email", "Please enter either Email Id or Mobile");
      form.setFieldError("mobile", "Please enter either Email Id or Mobile");
      return;
    }

    const joinedAt = formatJoinedAt(formData.joinedAt, isEditForm);
    const currentMonthlySalary = Number(formData.currentMonthlySalary);
    const maxWithdrawalPerTransaction = stringToNumberOrNull(
      formData.maxWithdrawalPerTransaction
    );
    const maxWithdrawalPerDay = stringToNumberOrNull(
      formData.maxWithdrawalPerDay
    );
    const maxWithdrawalSalaryPercent = stringToNumberOrNull(
      formData.maxWithdrawalSalaryPercent
    );

    const { id, ...restUserInput } = formData;
    const payload: CreateEmployee | UpdateEmployee = clearFormPayload({
      ...restUserInput,
      vendorId: vendorId || undefined,
      joinedAt,
      currentMonthlySalary,
      maxWithdrawalPerTransaction,
      maxWithdrawalPerDay,
      maxWithdrawalSalaryPercent,
    });
    let message: string;
    try {
      if (isEditForm) {
        if (!id) throw new Error("Can't able to update, Employee Id missing");
        await dispatch(storeDispatch, updateEmployee({ id, ...payload }));
        message = `Details of ${payload.fullName}, Employee Id: ${payload.employerEmployeeId} has been updated successfully`;
      } else {
        const resp: EmployeeDetails | Record<string, string> = await dispatch(
          storeDispatch,
          addEmployee(payload)
        );
        message = "Employee added successfully";

        // INFO: Hack - due to backend shitcode
        if (!resp._id) {
          const warnings = resp as Record<string, string>;
          Object.keys(warnings).forEach((warningKey) => {
            const formFieldPath =
              WARNING_RESP_TO_CREATE_EMPLOYEE_DTO_KEY_MAP[warningKey];
            if (!formFieldPath) return;
            const warnMessage: string | undefined = warnings[warningKey];
            form.setFieldError(formFieldPath, warnMessage);
          });
          return;
        }
      }
      await dispatch(storeDispatch, getEmployees({ vendorId }));
      setSuccessMessage(message);
    } catch (error) {
      setErrorMessage(getErrorMsgFromResponse(error, error?.message));
    }
  }

  const ContentHeader = (
    <p className="primary-color fw-600 m-0">
      {isEditForm ? "Edit Employee" : "Add Employee"}
    </p>
  );
  const CloseButton = (
    <Button
      size="lg"
      variant="secondary"
      color="brand"
      onClick={props.onEmployeeAdd}
    >
      Close
    </Button>
  );

  if (successMessage) {
    return (
      <Content header={ContentHeader} fixedHeaderFooter>
        <div className="d-flex align-items-center justify-content-center flex-column h-100">
          <CheckCircleIcon
            variant="fill"
            color="var(--clr-info-success-default)"
            size="el"
          />
          <h4 className="fs-20 fw-500 primary-color my-4 text-center">
            {successMessage}
          </h4>
          {!isEditForm && (
            <div className="my-3">
              <Button
                size="lg"
                variant="primary"
                color="brand"
                leftSection={<PlusIcon size="md" />}
                onClick={() => {
                  form.reset();
                  setFieldValidationStatus({});
                  setSuccessMessage("");
                  setErrorMessage("");
                }}
              >
                Add Another
              </Button>
            </div>
          )}
          {CloseButton}
        </div>
      </Content>
    );
  }

  if (errorMessage) {
    return (
      <Content header={ContentHeader} fixedHeaderFooter>
        <div className="d-flex align-items-center justify-content-center flex-column h-100">
          <WarningIcon
            variant="fill"
            color="var(--clr-info-alert-default)"
            size="el"
          />
          <h4 className="fs-20 fw-500 primary-color mt-4 text-center">
            Employee {isEditForm ? "updation" : "addition"} failed
          </h4>
          <p className="fs-14 fw-400 primary-color my-2">{errorMessage}</p>
          <div className="my-3">
            <Button
              size="lg"
              variant="primary"
              color="brand"
              onClick={() => setErrorMessage("")}
            >
              Try again
            </Button>
          </div>
          {CloseButton}
        </div>
      </Content>
    );
  }

  return (
    <Content
      header={ContentHeader}
      footer={
        <div className="d-flex alight-items-center justify-content-end">
          <Button
            size="lg"
            leftSection={<PlusIcon size="md" />}
            disabled={
              !form.isValid ||
              form.isAnyFieldLoading ||
              !isDataValidAfterServerCheck
            }
            form={formId}
          >
            {isEditForm ? "Update" : "Add"} Employee
          </Button>
        </div>
      }
      fixedHeaderFooter
    >
      <form
        id={formId}
        onSubmit={form.onSubmit(handleFormSubmit)}
        noValidate
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          columnGap: "24px",
          rowGap: "20px",
        }}
      >
        <TextInput
          label="Full Name"
          required
          autoFocus={!isEditForm}
          {...form.getInputProps("fullName")}
          hasClearButton
          onClear={() => form.setFieldValue("fullName", "")}
        />
        <TextInput
          label="Email ID"
          {...form.getInputProps("email")}
          success={fieldValidationStatus.email}
          hasClearButton={!fieldValidationStatus.email}
          onClear={() => form.setFieldValue("email", "")}
        />
        <TextInput
          label="Mobile"
          minLength={10}
          maxLength={10}
          {...form.getInputProps("mobile")}
          success={fieldValidationStatus.mobile}
          hasClearButton={!fieldValidationStatus.mobile}
          onClear={() => form.setFieldValue("mobile", "")}
        />
        <TextInput
          label="Employee ID"
          disabled={props.isSignedUp}
          required
          {...form.getInputProps("employerEmployeeId")}
          hasClearButton
          onClear={() => form.setFieldValue("employerEmployeeId", "")}
        />
        <NumberInput
          label="Monthly Salary"
          min={1}
          required
          {...form.getInputProps("currentMonthlySalary")}
          hasClearButton
          onClear={() => form.setFieldValue("currentMonthlySalary", "")}
        />
        <BaseInput
          type="date"
          label="Joining Date"
          {...form.getInputProps("joinedAt", {
            parser: (val) => {
              if (val instanceof Date) return val.toISOString();
              return;
            },
            formatter: (val) => {
              if (val)
                return dateToInputElemDate(new Date(val as string | number));
              return "";
            },
          })}
        />
        <TextInput
          label="Designation"
          {...form.getInputProps("designation")}
          hasClearButton
          onClear={() => form.setFieldValue("designation", "")}
        />
        {renderEmployerSpecificDrowdown(props.employerMetadata?.departmentIds, {
          label: "Department",
          placeholder: "Select a Department",
          inputProps: form.getInputProps("departmentId"),
        })}
        {renderEmployerSpecificDrowdown(props.employerMetadata?.gradeIds, {
          label: "Grade",
          placeholder: "Select a Grade",
          inputProps: form.getInputProps("gradeId"),
        })}
        {renderEmployerSpecificDrowdown(props.employerMetadata?.branchIds, {
          label: "Branch",
          placeholder: "Select a Branch",
          inputProps: form.getInputProps("branchId"),
        })}
        <TextInput
          label="Salary Account Number"
          {...form.getInputProps("salaryAccountNumber")}
          hasClearButton
          onClear={() => form.setFieldValue("salaryAccountNumber", "")}
        />
        <RadioGroup
          label="Transaction Allowed"
          required
          {...form.getInputProps("isTransactionAllowed", {
            withRef: false,
            parser: (val) => val === "true",
          })}
        >
          <Radio label="Yes" value="true" />
          <Radio label="No" value="false" />
        </RadioGroup>
        {renderEmployerSpecificDrowdown(TRANSACTION_ACCESS_UPDATE_REASON, {
          label: "Transaction access update reason (optional)",
          placeholder: "Select a transaction blocking/unblocking reason",
          inputProps: form.getInputProps("transactionAccessUpdateReason"),
        })}
        <NumberInput
          label="Max Withdrawal Salary Percentage"
          min={0}
          {...form.getInputProps("maxWithdrawalSalaryPercent", {
            defaultValueForEmptyField: "",
          })}
          hasClearButton
          onClear={() => form.setFieldValue("maxWithdrawalSalaryPercent", "")}
        />
        <NumberInput
          label="Maximum Salary Withdrawal Per Transaction"
          min={0}
          {...form.getInputProps("maxWithdrawalPerTransaction", {
            defaultValueForEmptyField: "",
          })}
          hasClearButton
          onClear={() => form.setFieldValue("maxWithdrawalPerTransaction", "")}
        />
        <NumberInput
          label="Maximum Salary Withdrawal Per Day"
          min={0}
          {...form.getInputProps("maxWithdrawalPerDay", {
            defaultValueForEmptyField: "",
          })}
          hasClearButton
          onClear={() => form.setFieldValue("maxWithdrawalPerDay", "")}
        />
      </form>
    </Content>
  );
}
